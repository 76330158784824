import React from "react";
import "./ChangeSpeedPopUp.css";
import { TextInput, TextInputType } from "./TextInput";
import Button from "./Button";

interface ChangeSpeedPopUpProps {
  changeRoadSpeedForm: {
    speed: string | null;
    reverseSpeed: string | null;
  };
  updateSpeed: (formData: {
    speed?: string | null;
    reverseSpeed?: string | null;
  }) => void;
  saveRoad: () => void;
}

export default class ChangeSpeedPopUp extends React.Component<ChangeSpeedPopUpProps> {
  render() {
    return (
      <div id="ChangeSpeedPopUp">
        <div id="ChangeSpeedPopUp_Box">
          <div id="ChangeSpeedPopUp_ContentWrapper">
            <div id="ChangeSpeedPopUp_Title">Wat wordt de nieuw snelheid?</div>

            <div id="ChangeSpeedPopUp_Section">
              <div className="ChangeSpeedPopUp_Question">Speed</div>
              <TextInput
                // style={inputStyling}
                value={`${this.props.changeRoadSpeedForm.speed}`}
                onChange={(newSpeed) => {
                  this.props.updateSpeed({
                    speed: newSpeed,
                  });
                }}
                // focusedStyle={inputFocusStyling}
                placeholderColor={"#BBB"}
                placeholder={"Speed"}
                type={TextInputType.RoadSpeed}
              ></TextInput>
              <div className="ChangeSpeedPopUp_Question">Reverse speed</div>
              <TextInput
                // style={inputStyling}
                value={`${this.props.changeRoadSpeedForm.reverseSpeed}`}
                onChange={(newSpeed) => {
                  this.props.updateSpeed({
                    reverseSpeed: newSpeed,
                  });
                }}
                // focusedStyle={inputFocusStyling}
                placeholderColor={"#BBB"}
                placeholder={"Speed"}
                type={TextInputType.RoadSpeed}
              ></TextInput>
            </div>

            <div id="ChangeSpeedPopUp_SubmitButtonHolder">
              <Button
                // style={submitReportButton}
                // hoverStyle={submitReportButtonHover}
                onClick={() => {
                  this.props.saveRoad();
                }}
              >
                Snelheid opslaan
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
